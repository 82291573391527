/* import __COLOCATED_TEMPLATE__ from './beneficiary-form.hbs'; */
/* eslint-disable ember/no-array-prototype-extensions */
import { InvalidError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { all, dropTask } from 'ember-concurrency';

import { ACCOUNT_TYPES, COUNTRY_MISMATCH_ERROR_CODES } from 'qonto/constants/beneficiaries';
import { PREFERRED_COUNTRIES_FX } from 'qonto/constants/countries';
import CURRENCIES from 'qonto/constants/currencies';
import {
  mapUnsupportedIdentifierCountryError,
  parseInvalidBeneficiaryErrors,
} from 'qonto/utils/beneficiaries';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';
import scrollIntoView from 'qonto/utils/scroll-into-view';
import { sortByPreferedCountries } from 'qonto/utils/sorters';

export default class TransfersBeneficiaryFxFormComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service abilities;
  @service countriesManager;
  @service intl;
  @service networkManager;
  @service store;

  @tracked countries = [];
  @tracked addressRequirements = {};

  @tracked isValidationEnabled = false;

  @tracked validationDisclaimerErrors = [];
  @tracked providerError = null;

  currenciesTranslations = {
    AED: `AED - ${this.intl.t(`currency.AED`)}`,
    AUD: `AUD - ${this.intl.t(`currency.AUD`)}`,
    BHD: `BHD - ${this.intl.t(`currency.BHD`)}`,
    BGN: `BGN - ${this.intl.t(`currency.BGN`)}`,
    CAD: `CAD - ${this.intl.t(`currency.CAD`)}`,
    CHF: `CHF - ${this.intl.t(`currency.CHF`)}`,
    CNY: `CNY - ${this.intl.t(`currency.CNY`)}`,
    CSK: `CSK - ${this.intl.t(`currency.CSK`)}`,
    CZK: `CZK - ${this.intl.t(`currency.CZK`)}`,
    DKK: `DKK - ${this.intl.t(`currency.DKK`)}`,
    EUR: `EUR - ${this.intl.t(`currency.EUR`)}`,
    GBP: `GBP - ${this.intl.t(`currency.GBP`)}`,
    HKD: `HKD - ${this.intl.t(`currency.HKD`)}`,
    HRK: `HRK - ${this.intl.t(`currency.HRK`)}`,
    HUF: `HUF - ${this.intl.t(`currency.HUF`)}`,
    ILS: `ILS - ${this.intl.t(`currency.ILS`)}`,
    JPY: `JPY - ${this.intl.t(`currency.JPY`)}`,
    NOK: `NOK - ${this.intl.t(`currency.NOK`)}`,
    NZD: `NZD - ${this.intl.t(`currency.NZD`)}`,
    PLN: `PLN - ${this.intl.t(`currency.PLN`)}`,
    RON: `RON - ${this.intl.t(`currency.RON`)}`,
    SAR: `SAR - ${this.intl.t(`currency.SAR`)}`,
    SEK: `SEK - ${this.intl.t(`currency.SEK`)}`,
    SGD: `SGD - ${this.intl.t(`currency.SGD`)}`,
    THB: `THB - ${this.intl.t(`currency.THB`)}`,
    TND: `TND - ${this.intl.t(`currency.TND`)}`,
    TRY: `TRY - ${this.intl.t(`currency.TRY`)}`,
    USD: `USD - ${this.intl.t(`currency.USD`)}`,
    ZAR: `ZAR - ${this.intl.t(`currency.ZAR`)}`,
  };

  constructor() {
    super(...arguments);

    all([this.loadCountriesTask.perform(), this.loadCountriesRequirementTask.perform()]).catch(
      ignoreCancelation
    );
  }

  get amountCurrencies() {
    let currencies = this.selectedCountry.supportedCurrencies;

    // make sure to prevent not supported by Kantox EUR to EUR fx transfers
    return currencies.filter(currency => currency !== CURRENCIES.default).sort();
  }

  get selectedCountry() {
    let beneficiaryCountry = this.beneficiary.country;
    return this.countries.find(country => country.code === beneficiaryCountry);
  }

  get availableAccountTypes() {
    return this.selectedCountry?.types || [];
  }

  get beneficiary() {
    return this.args.beneficiary;
  }

  get canReadCustomLabels() {
    return this.abilities.can('view bookkeeping') && this.abilities.can('read custom-labels');
  }

  get fxCountriesCodes() {
    return this.countries.map(country => country.code);
  }

  get hasLabelsList() {
    return this.beneficiary.organization.get('labelLists').length > 0;
  }

  get hasMultipleAccountTypes() {
    return this.availableAccountTypes.length > 1;
  }

  get providerErrorMessage() {
    let accountType = this.providerError?.accountType;
    if (accountType === ACCOUNT_TYPES.IBAN) {
      return this.intl.t('transfers.errors.iban-provider-unknown-error');
    } else if (accountType === ACCOUNT_TYPES.BIC_SWIFT) {
      return this.intl.t('transfers.errors.bic-provider-unknown-error');
    } else if (accountType === ACCOUNT_TYPES.ABA) {
      return this.intl.t('transfers.errors.aba-provider-unknown-error');
    }
  }

  get selectedCurrency() {
    let localCurrency = this.beneficiary.currency;
    return localCurrency ? this.currenciesTranslations[localCurrency] : '';
  }

  get defaultCurrency() {
    let { defaultCurrency } = this.selectedCountry;
    return this.amountCurrencies.includes(defaultCurrency) ? defaultCurrency : false;
  }

  loadCountriesTask = dropTask(async () => {
    let countries = await this.countriesManager.loadCountries();
    this.countries = this._parseCountries(countries);
  });

  loadCountriesRequirementTask = dropTask(async () => {
    let { requirements } = await this.store.adapterFor('beneficiary').fetchCountriesRequirements();

    this.addressRequirements = requirements?.reduce((acc, { country, address }) => {
      acc[country] = {
        dependsOnType: address.depends_on_type,
        dependsOnValue: address.depends_on_value,
      };
      return acc;
    }, {});
  });

  get isAddressRequired() {
    let addressRequirements = this.addressRequirements[this.beneficiary.country];

    if (!addressRequirements) {
      return false;
    }

    let { dependsOnType, dependsOnValue } = addressRequirements;

    if (dependsOnType === 'currency' && dependsOnValue !== this.beneficiary.currency) {
      return false;
    }

    return true;
  }

  saveBeneficiaryTask = dropTask(async () => {
    this.isValidationEnabled = false;
    this.validationDisclaimerErrors = [];
    this.providerError = null;
    this.beneficiary.errors.clear();

    // Activate address validation
    this.beneficiary.isAddressRequired = this.isAddressRequired;

    let { validations } = await this.beneficiary.validate();
    this.isValidationEnabled = true;

    if (validations.isValid) {
      try {
        await this.beneficiary.save();
        this.args.transitionToNext(this.beneficiary);
      } catch (error) {
        if (error instanceof InvalidError && error.errors) {
          let { errors } = error;

          let hasUnknownErrors = errors
            .map(error => error.detail)
            .some(({ code }) => code === 'unknown_error');

          if (hasUnknownErrors) {
            errors = errors.filter(error => error.detail.code !== 'unknown_error');
            this.providerError = {
              accountType: this.beneficiary.accountType,
            };
          }

          errors = errors.map(error =>
            mapUnsupportedIdentifierCountryError(error, this.fxCountriesCodes)
          );
          let { disclaimerErrors, fieldErrors } =
            this._partitionBeneficiaryValidationErrors(errors);
          let parsedFieldErrors = parseInvalidBeneficiaryErrors(fieldErrors);
          this.networkManager.errorModelInjector(this.beneficiary, parsedFieldErrors, error);

          if (!fieldErrors.length) {
            this.validationDisclaimerErrors = disclaimerErrors;
          }

          this._scrollToError();
        }
      }
    } else {
      this._scrollToError();
    }
  });

  preferredCountries = PREFERRED_COUNTRIES_FX;
  @action
  saveLabels(labelsList, label) {
    let labelsToUpdate = getLabelsToUpdate(this.beneficiary.labels, labelsList, label);
    this.beneficiary.labels = labelsToUpdate;
  }

  @action
  updateBeneficiaryCountry(country) {
    this.beneficiary.country = country;
    this._handleBeneficiaryCountryUpdate();
    this.beneficiary.isAddressRequired = this.isAddressRequired;
    if (this.isAddressRequired) {
      this.beneficiary.address = { country };
    } else {
      this.beneficiary.set('address', undefined);
    }
  }

  @action
  updateBeneficiaryCurrency(currency) {
    this.beneficiary.currency = currency;
    this.beneficiary.isAddressRequired = this.isAddressRequired;
    if (this.isAddressRequired) {
      this.beneficiary.address = { country: this.beneficiary.country };
    } else {
      this.beneficiary.set('address', undefined);
    }
  }

  _handleBeneficiaryCountryUpdate() {
    if (!this.hasMultipleAccountTypes) {
      let [onlyAccountTypeAvailable] = this.availableAccountTypes;
      this.beneficiary.accountType = onlyAccountTypeAvailable;
    } else if (!this.availableAccountTypes.includes(this.beneficiary.accountType)) {
      this.beneficiary.accountType = undefined;
    }
    if (this.defaultCurrency) {
      this.beneficiary.currency = this.defaultCurrency;
    }
  }

  _parseCountries(countries) {
    return countries
      .map(({ isoCode, accountTypes, defaultCurrency, supportedCurrencies }) => ({
        code: isoCode,
        types: accountTypes,
        defaultCurrency,
        supportedCurrencies,
        name: this.intl.formatCountry(isoCode),
      }))
      .sort(sortByPreferedCountries);
  }

  _partitionBeneficiaryValidationErrors(errors) {
    let disclaimerErrors = [];
    let fieldErrors = [];

    errors.forEach(error =>
      (COUNTRY_MISMATCH_ERROR_CODES.includes(error.detail.code)
        ? disclaimerErrors
        : fieldErrors
      ).push(error)
    );

    return {
      disclaimerErrors,
      fieldErrors,
    };
  }

  _scrollToError() {
    next(() => scrollIntoView('[data-has-error]'));
  }
}
