export default {
  "wrapper": "qLG",
  "title": "qLr",
  "activity-tag": "qLb",
  "select": "qLy",
  "tooltip": "qLU",
  "date-picker": "qLj",
  "internal-notes": "qLW",
  "scheduled": "qLu"
};
