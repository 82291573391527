export default {
  "modal": "qsg",
  "section": "qsD",
  "header": "qsV",
  "title": "qsL",
  "icon": "qsT",
  "content": "qsz",
  "export-template-wrapper": "qsa",
  "export-template-radio": "qsH",
  "export-template-content": "qsO",
  "export-template-label": "qsm",
  "export-template-button": "qsp",
  "export-template-list": "qsx",
  "inactive": "qsw",
  "export-template-title": "qsN",
  "export-template-description": "qsv",
  "export-template-item-content": "qso",
  "permission-disclaimer-container": "qsn",
  "export-template-footer": "qsi",
  "export-template-cta-container": "qsA",
  "upsell": "qsY",
  "upsell-section": "qsh",
  "_content": "qsf",
  "_cta": "qsK",
  "upsell-title": "qsG",
  "upsell-description": "qsr"
};
