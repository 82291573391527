export default {
  "row": "qLT",
  "col": "qLz",
  "header": "qLa",
  "block": "qLH",
  "col-5": "qLO",
  "col-6": "qLm",
  "col-9": "qLp",
  "col-12": "qLx",
  "hide": "qLw"
};
