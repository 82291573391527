/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { dropTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  apiBaseURL,
  transactionNewExportNamespace,
  transactionOldExportNamespace,
} from 'qonto/constants/hosts';
import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { MAX_CUSTOM_TEMPLATES } from 'qonto/constants/transactions-export';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

const illustrationsData = {
  DE: {
    path: '/illustrations/transfers/connect-banner-apps-DE.png',
    apps: ['Notion', 'Google Sheets', 'One Drive', 'Google Drive', 'Datev', 'Lexoffice'].join(', '),
  },
  ES: {
    path: '/illustrations/transfers/connect-banner-apps-ES.png',
    apps: ['Notion', 'Google Sheets', 'One Drive', 'Google Drive', 'Holded', 'Xero'].join(', '),
  },
  FR: {
    path: '/illustrations/transfers/connect-banner-apps-FR.png',
    apps: [
      'Notion',
      'Google Sheets',
      'One Drive',
      'Google Drive',
      'JeDeclare',
      'Sage',
      'Pennylane',
      'Cegid Loop',
    ].join(', '),
  },
  IT: {
    path: '/illustrations/transfers/connect-banner-apps-IT.png',
    apps: ['Notion', 'Google Sheets', 'One Drive', 'Google Drive', 'Zoho Books', 'TeamSystem'].join(
      ', '
    ),
  },
};

const mapOfSelectableFormats = {
  extended: ['excel', 'csv'],
  simple: ['excel', 'csv'],
  mt940: ['standard', 'datev'],
};

export default class TransactionsCustomExportsModalComponent extends Component {
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service networkManager;
  @service segment;
  @service sentry;
  @service store;
  @service userManager;
  @service abilities;
  @service modals;
  @service subscriptionManager;
  @service featuresManager;

  TRACKING_TRIAL_STATE = TRACKING_TRIAL_STATE;

  @tracked selectedExportTemplateId = safeLocalStorage.getItem(
    'export-transactions-selected-export-template-id'
  );

  @tracked selectedFormat;
  @tracked includeAttachments = false;
  @tracked showSuccessScreen = false;
  @tracked displayConnectBanner =
    !safeLocalStorage.getItem('export-transactions-connect-banner-dismissed') &&
    this.illustrationData;

  @reads('organizationManager.organization.id') currentOrganizationId;
  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  constructor() {
    super(...arguments);

    assert('The @data.filters argument needs to be passed.', Boolean(this.args.data?.filters));

    this._preselectFormat();
    this.loadData();
  }

  exportTemplateSlugToId(formId, format) {
    if (format === 'excel') {
      format = 'xls';
    }

    let slug = `${format}_${formId}`;
    let exportTemplate = this.exportTemplates.find(exportTemplate => exportTemplate.slug === slug);

    if (!exportTemplate) {
      throw new Error(`Unable to find export template record for ${slug}`);
    }

    return exportTemplate.id;
  }

  get exportTemplates() {
    return this.loadDataTask.last?.value?.exportTemplates ?? [];
  }

  get insights() {
    return this.loadDataTask.last?.value?.insights;
  }

  get hasOnlyDeclinedTransactionsSelected() {
    let { pending, completed, reversed, declined } = this.insights;
    return [pending, completed, reversed].every(value => !value) && declined;
  }

  get isDisabled() {
    return (
      this.exportTask.isRunning ||
      this.maxCustomTemplatesReached ||
      this.hasOnlyDeclinedTransactionsSelected
    );
  }

  _preselectFormat() {
    let format = safeLocalStorage.getItem('export-transactions-selected-export-format');
    let selectableFormatsOfTemplateId = mapOfSelectableFormats[this.selectedExportTemplateId];
    let isFormatExistInSelectableFormats = Object.values(mapOfSelectableFormats).find(
      selectableFormat => selectableFormat.includes(format)
    );
    let hasFormatInSelectableFormats = selectableFormatsOfTemplateId?.some(selectableFormat =>
      selectableFormat.includes(format)
    );

    if (format && isFormatExistInSelectableFormats) {
      if (hasFormatInSelectableFormats || !selectableFormatsOfTemplateId) {
        this.selectedFormat = format;
      } else {
        this.updateSelectedFormat(selectableFormatsOfTemplateId[0]);
      }
    } else {
      if (selectableFormatsOfTemplateId) {
        this.selectedFormat = selectableFormatsOfTemplateId[0];
      } else {
        this.selectedFormat = mapOfSelectableFormats.extended[0];
      }
    }
  }

  @action
  disclaimerAndTooltipForExportTemplate(exportTemplate) {
    if (!exportTemplate) {
      return;
    }

    let translationKey = `transactions.exports.export.export-type`;
    let translationKeySuffix = this.hasExternalAccounts ? '-external' : '';

    if (exportTemplate.isDefault) {
      switch (exportTemplate.formId) {
        case 'extended':
          return {
            disclaimer: this.intl.t(
              `${translationKey}.transaction-counter-disclaimer.executed-processing${translationKeySuffix}`,
              {
                count: this.insights.completed + this.insights.pending,
              }
            ),
            tooltip: this.intl.t(`${translationKey}.tooltip.full-custom`),
          };
        case 'xls_teamsystem':
          return {
            disclaimer: this.intl.t(
              `${translationKey}.transaction-counter-disclaimer.executed-reverted${translationKeySuffix}`,
              {
                count: this.insights.completed + this.insights.reversed,
              }
            ),
            tooltip: this.intl.t(`${translationKey}.tooltip.team-system`),
          };
        default:
          return {
            disclaimer: this.intl.t(`${translationKey}.transaction-counter-disclaimer.executed`, {
              count: this.insights.completed,
            }),
            tooltip: this.intl.t(`${translationKey}.tooltip.simple-ofx-qif-mt940`),
          };
      }
    } else {
      if (exportTemplate.exportFullDataSet) {
        return {
          disclaimer: this.intl.t(
            `${translationKey}.transaction-counter-disclaimer.generic${translationKeySuffix}`,
            {
              count:
                this.insights.completed +
                this.insights.pending +
                this.insights.declined +
                this.insights.reversed,
            }
          ),
          tooltip: this.intl.t(`${translationKey}.tooltip.full-custom`),
        };
      }

      return {
        disclaimer: this.intl.t(
          `${translationKey}.transaction-counter-disclaimer.generic${translationKeySuffix}`,
          {
            count: this.insights.completed + this.insights.pending,
          }
        ),
        tooltip: this.intl.t(`${translationKey}.tooltip.full-custom`),
      };
    }
  }

  @cached
  get exportTemplatesList() {
    let templateMap = new Map();
    for (let exportTemplate of this.exportTemplates) {
      let { disclaimer, tooltip } = this.disclaimerAndTooltipForExportTemplate(exportTemplate);
      let isDisabled = !disclaimer;

      let slug = exportTemplate.formId;

      let selectableFormats;
      if (exportTemplate.hasFormatSelection && mapOfSelectableFormats[slug]) {
        selectableFormats = mapOfSelectableFormats[slug];
      }

      // this filters the duplicates for default templates with format-selection
      if (!templateMap.has(slug)) {
        templateMap.set(slug, {
          exportTemplate,
          disclaimer,
          tooltip,
          isDisabled,
          selectableFormats,
        });
      }
    }
    return templateMap;
  }

  get maxCustomTemplatesReached() {
    return this.exportTemplates.length > MAX_CUSTOM_TEMPLATES;
  }

  get selectedExportTemplate() {
    if (!this.selectedExportTemplateId) {
      return;
    }

    let data = this.exportTemplatesList.get(this.selectedExportTemplateId);
    if (!data) {
      return;
    }

    let { exportTemplate, isDisabled } = data;
    if (isDisabled) {
      return;
    }

    // if we have a default template with format selection, we may need to map type + format
    // to the actual export-template record. For example: xls + extended -> xls_extended.
    if (exportTemplate.isDefault && exportTemplate.hasFormatSelection) {
      let id = this.exportTemplateSlugToId(this.selectedExportTemplateId, this.selectedFormat);
      exportTemplate = this.exportTemplates.find(exportTemplate => exportTemplate.id === id);
      assert(`Expected export template to exist with id "${id}"`, exportTemplate);
    }

    return exportTemplate;
  }

  /** @type {string|undefined} */
  get separator() {
    if (['csv_simple', 'csv_extended'].includes(this.selectedExportTemplate.id)) {
      return this.localeManager.locale === 'en' ? ',' : ';';
    }
  }

  get showPermissionsDisclaimer() {
    return !(
      this.abilities.can('read bankAccount') &&
      this.abilities.can('full account details bankAccount') &&
      this.abilities.can('see sensitive fields beneficiaries')
    );
  }

  loadDataTask = task(async () => {
    let insightsRequest = this.store.adapterFor('transaction').insights(this.args.data.filters);
    let exportTemplatesRequest = this.store.query('export-template', {
      organization_id: this.currentOrganizationId,
    });

    let [insights, exportTemplates] = await Promise.all([insightsRequest, exportTemplatesRequest]);

    return { exportTemplates, insights };
  });

  @action
  close() {
    this.segment.track('export-page_close-button_clicked');
    this.args.close();
  }

  @action
  loadData() {
    this.loadDataTask.perform().catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });
  }

  @action
  updateSelectedExportTemplateId(value) {
    this.selectedExportTemplateId = value;

    let { exportTemplate, selectableFormats } = this.exportTemplatesList.get(value);
    let safeLocalStorageSelectedExportFormat = safeLocalStorage.getItem(
      'export-transactions-selected-export-format'
    );

    if (
      exportTemplate.hasFormatSelection &&
      !selectableFormats.includes(safeLocalStorageSelectedExportFormat)
    ) {
      this.updateSelectedFormat(selectableFormats[0]);
    }

    safeLocalStorage.setItem('export-transactions-selected-export-template-id', value);
  }

  @action
  updateSelectedFormat(value) {
    this.selectedFormat = value;

    safeLocalStorage.setItem('export-transactions-selected-export-format', value);
  }

  @action
  toggleIncludeAttachments(checked) {
    this.includeAttachments = checked;
  }

  exportTask = task(async () => {
    this.segment.track('export-page_generate-export_clicked', {
      export_type: this.selectedExportTemplate.isDefault
        ? this.selectedExportTemplate.id
        : 'custom',
      format: this.selectedExportTemplate.format,
      has_attachments: this.includeAttachments,
      separator: this.separator ?? 'n/a',
    });

    await this.networkManager.request(
      `${apiBaseURL}/${transactionNewExportNamespace}/transactions/search_export`,
      {
        method: 'POST',
        data: {
          ...this.args.data.filters,
          export_template_id: this.selectedExportTemplate.id,
          separator: this.separator,
          with_attachments: this.includeAttachments,
        },
      }
    );
    this.showSuccessScreen = true;
  });

  @action
  export(event) {
    event.preventDefault();

    this.exportTask.perform().catch(error => {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(
        this.errors.messageForStatus(error) ?? this.intl.t('errors.unexpected_error_occured')
      );
    });
  }

  deleteExportTemplateTask = task(async (close, { data: exportTemplate }) => {
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${transactionOldExportNamespace}/transactions/export_templates/${exportTemplate.id}`,
        {
          method: 'DELETE',
        }
      );
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(
        this.errors.messageForStatus(error) ?? this.intl.t('errors.unexpected_error_occured')
      );

      close('error');
      return;
    }

    this.segment.track('custom-template-deletion-modal_delete-export-template-button_clicked');
    exportTemplate.unloadRecord();
    close('confirm');

    this.toastFlashMessages.toastInfo(
      this.intl.t('transactions.exports.custom-template.toasts.deleted')
    );
  });

  @action
  deleteExportTemplate(exportTemplate, event) {
    event.preventDefault();

    this.segment.track('export-page_delete-export-template-button_clicked');

    this.modals.open('popup/destructive', {
      title: this.intl.t('transactions.exports.custom-template.delete.title'),
      description: this.intl.t('transactions.exports.custom-template.delete.description'),
      cancel: this.intl.t('transactions.exports.custom-template.delete.cancel-cta'),
      confirm: this.intl.t('transactions.exports.custom-template.delete.delete-cta'),
      confirmTask: this.deleteExportTemplateTask,
      data: exportTemplate,
    });
  }

  get illustrationData() {
    return illustrationsData[this.organizationManager.organization?.legalCountry];
  }

  @action
  trackConnectBanner() {
    this.segment.track('export_transactions_connect_banner_clicked', {
      role: this.organizationManager.membership?.role,
      country_selected: this.organizationManager.organization?.legalCountry,
    });
  }

  @action
  dismissConnectBanner(event) {
    event.stopPropagation();
    event.preventDefault();
    safeLocalStorage.setItem('export-transactions-connect-banner-dismissed', true);
    this.displayConnectBanner = false;

    this.segment.track('export_transactions_connect_banner_dismissed', {
      role: this.organizationManager.membership?.role,
      country_selected: this.organizationManager.organization?.legalCountry,
    });
  }

  openCustomTemplateModalTask = dropTask(async exportTemplate => {
    if (exportTemplate) {
      this.segment.track('export-page_edit-export-template-button_clicked');
    } else {
      this.segment.track('export-page_create-export-template-button_clicked');
    }

    let modal;
    let result;
    try {
      modal = this.modals.open(
        'transactions/custom-exports/custom-template-modal',
        {
          isFullScreenModal: true,
          columns: this.exportTemplates?.meta?.columns ?? [],
          exportTemplate,
        },
        {
          focusTrapOptions: {
            clickOutsideDeactivates: false,
            escapeDeactivates: false,
          },
        }
      );

      result = await modal;
    } finally {
      if (result === 'success') {
        this.loadData();
      }

      if (!modal.isClosing) {
        modal.close();
      }
    }
  });
}
