export default {
  "wrapper": "qTC",
  "ember-power-select-dropdown": "qTk",
  "status": "qTJ body-1",
  "error": "qTs",
  "success": "qTF",
  "type-container": "qTg",
  "type-close": "qTD",
  "date": "qTV",
  "close-icon": "qTL",
  "container": "qTT",
  "container-picto": "qTz",
  "avatar": "qTa",
  "container-picto-status": "qTH",
  "general": "qTO",
  "general-amount": "qTm",
  "unprocessed": "qTp",
  "fx-rate": "qTx",
  "general-counterparty": "qTw body-2",
  "infobox": "qTN",
  "account-infobox": "qTv qTN",
  "initiator-infobox": "qTo qTN",
  "transaction-infobox": "qTn qTN",
  "repeat-infobox": "qTi qTN",
  "item": "qTA body-2",
  "eye-icon": "qTY",
  "repeat-transfer-action": "qTh",
  "disclaimer": "qTf",
  "start-verification-link": "qTK",
  "financing-not-compliant-disclaimer": "qTG"
};
