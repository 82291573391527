export default {
  "transfer-form": "qzv",
  "transfer-form-title": "qzo",
  "transfer-form-subtitle": "qzn",
  "form": "qzi",
  "form-footer": "qzA",
  "footer-btn": "qzY",
  "header": "qzh",
  "main-settings": "qzf",
  "additional-settings": "qzK",
  "form-container": "qzG"
};
