export default {
  "header-cell": "qai",
  "empty": "qaA",
  "header-type": "qaY",
  "header-requester": "qah",
  "header-amount": "qaf",
  "header-approval": "qaK",
  "header-content": "qaG caption-bold",
  "active": "qar",
  "row-compact": "qab",
  "row-sidebar": "qay",
  "hidden": "qaU",
  "animated": "qaj",
  "fadein-item": "qaW",
  "animated-cell": "qau"
};
