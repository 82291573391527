export default {
  "card-header": "qDC",
  "counterparty-name": "qDk",
  "pan-section": "qDJ header-section body-2",
  "infobox": "qDs header-section",
  "see-card-button": "qDF",
  "card-identifier-section": "qDg",
  "card-icon": "qDD",
  "nickname": "qDV body-1",
  "pan": "qDL body-2"
};
