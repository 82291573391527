export default {
  "vat-lines": "qpU",
  "vat-line": "qpj",
  "vat-input": "qpW",
  "vat-rate": "qpu",
  "vat-option-button": "qxS",
  "disabled": "qxc",
  "vat-option-tooltip": "qxq",
  "remove-label": "qxZ",
  "remove-button": "qxR",
  "vat-total": "qxQ",
  "vat-total-amount": "qxe",
  "add-vat-line": "qxB",
  "with-vat-lines": "qxE"
};
