export default {
  "row": "qHd body-1",
  "active": "qHI",
  "cell": "qHt",
  "empty": "qHM",
  "ellipsis": "qHP",
  "text-secondary": "qHl",
  "transfer-type": "qHX",
  "transfer-type__icon": "qHC",
  "quick-actions": "qHk",
  "amount": "qHJ",
  "cell-approve": "qHs",
  "cell-type": "qHF",
  "cell-requester": "qHg",
  "account-select": "qHD",
  "row-compact": "qHV",
  "row-sidebar": "qHL",
  "cell-amount": "qHT",
  "hidden": "qHz",
  "animated": "qHa",
  "fadein-item": "qHH",
  "animated-cell": "qHO"
};
