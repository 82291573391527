export default {
  "row": "qpt",
  "col": "qpM",
  "counterparty": "qpP",
  "header": "qpl",
  "icon": "qpX",
  "col-12": "qpC",
  "col-8": "qpk",
  "col-6": "qpJ",
  "col-4": "qps",
  "col-2": "qpF",
  "hide": "qpg"
};
