export default {
  "row": "qOR body-1",
  "active": "qOQ",
  "cell": "qOe",
  "empty": "qOB",
  "ellipsis": "qOE",
  "text-secondary": "qOd",
  "cell-type": "qOI",
  "cell-reason": "qOt",
  "cell-amount": "qOM",
  "row-sidebar": "qOP",
  "animated": "qOl",
  "fadein-item": "qOX",
  "animated-cell": "qOC"
};
