export default {
  "fileupload": "qpo",
  "file": "qpn",
  "label": "qpi",
  "zone": "qpA",
  "error": "qpY",
  "hide": "qph",
  "dropzone": "qpf",
  "hidden": "qpK",
  "upload-icon": "qpG",
  "dropping": "qpr",
  "bounce2": "qpb",
  "error-message": "qpy"
};
