export default {
  "wrapper": "qJh",
  "section-wrapper": "qJf",
  "header": "qJK",
  "title": "qJG body-1",
  "close-icon": "qJr",
  "section": "qJb",
  "section-title": "qJy caption-bold",
  "labels": "qJU",
  "labels-title": "qJj",
  "section-checkbox": "qJW",
  "grow": "qJu scroll",
  "label": "qsS",
  "label-spacing": "qsc",
  "buttons": "qsq"
};
