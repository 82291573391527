export default {
  "sidebar-header": "qDm",
  "sidebar-header__top": "qDp",
  "sidebar-header__body": "qDx",
  "sidebar-header__content": "qDw",
  "sidebar-header__date": "qDN",
  "infobox": "qDv",
  "infobox--link": "qDo",
  "ghost": "qDn",
  "fail": "qDi",
  "r-transaction-disclaimer": "qDA",
  "hold-disclaimer": "qDY",
  "transaction-link": "qDh body-2",
  "icon-link": "qDf"
};
