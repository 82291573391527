export default {
  "header-cell": "qmv",
  "align-right": "qmo",
  "header-content": "qmn caption-bold",
  "active": "qmi",
  "empty": "qmA",
  "col-12": "qmY",
  "col-8": "qmh",
  "col-6": "qmf",
  "col-4": "qmK",
  "col-2": "qmG",
  "hide": "qmr"
};
