export default {
  "button-wrapper": "qFb",
  "btn-filter": "qFy",
  "name-wrapper": "qFU",
  "preset-name": "qFj",
  "cancel-placeholder": "qFW",
  "cancel-button": "qFu",
  "dropdown-opened": "qgS",
  "dropdown-closed": "qgc",
  "dropdown-menu": "qgq",
  "loading-item": "qgZ",
  "placeholder": "qgR",
  "list-body": "qgQ",
  "list-item": "qge",
  "error-state": "qgB body-2",
  "warning": "qgE",
  "warning-icon": "qgd",
  "footer": "qgI",
  "add-icon": "qgt",
  "empty-state": "qgM"
};
