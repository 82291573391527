export default {
  "header-cell": "qVh",
  "header-content": "qVf caption-bold",
  "active": "qVK",
  "align-right": "qVG",
  "empty": "qVr",
  "align-checkbox": "qVb",
  "col-5": "qVy",
  "col-9": "qVU",
  "emitted-at-header-tag": "qVj"
};
