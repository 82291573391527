export default {
  "custom-export-loading": "qsu",
  "title": "qFS",
  "description": "qFc",
  "section": "qFq",
  "placeholder-avatar": "qFZ",
  "_with-avatar": "qFR",
  "_with-list": "qFQ",
  "list-item": "qFe",
  "radio": "qFB",
  "header": "qFE",
  "header-line": "qFd"
};
