export default {
  "beneficiary-details": "qzt",
  "beneficiary-country-disclaimer": "qzM",
  "beneficiary-account-types": "qzP",
  "divider": "qzl",
  "error-disclaimer": "qzX",
  "label": "qzC",
  "edition-info-disclaimer": "qzk",
  "sticky-panel": "qzJ",
  "spinner": "qzs"
};
