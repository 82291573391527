export default {
  "sidebar-content": "qTQ",
  "section": "qTe",
  "header-wrapper": "qTB",
  "transfer-id": "qTE caption",
  "small-padding": "qTd",
  "title": "qTI",
  "subtitle": "qTt",
  "actions": "qTM"
};
