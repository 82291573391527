export default {
  "row": "qmb",
  "counterparty-name": "qmy",
  "counterparty-status": "qmU",
  "amount": "qmj",
  "next-date": "qmW",
  "end-date": "qmu",
  "frequency": "qpS",
  "active": "qpc",
  "cell": "qpq body-2",
  "no-padding": "qpZ",
  "cell-content": "qpR",
  "counterparty": "qpQ",
  "status-avatar": "qpe",
  "beneficiary-name": "qpB",
  "empty": "qpE",
  "disabled": "qpd",
  "fadein-item": "qpI"
};
