export default {
  "container": "qgv",
  "header": "qgo",
  "title": "qgn caption-bold",
  "text": "qgi body-2",
  "trigger": "qgA",
  "loading-state": "qgY",
  "dropzone-placeholder-wrapper": "qgh",
  "dropzone-placeholder": "qgf",
  "file-loading": "qgK"
};
