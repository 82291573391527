export default {
  "filters-checkbox": "qFL mb-8",
  "csv-options-button": "qFT",
  "active": "qFz",
  "options-format-settings-wrapper": "qFa",
  "checkbox": "qFH",
  "options-format-settings": "qFO",
  "hidden": "qFm",
  "visible": "qFp",
  "disabled": "qFx",
  "buttons": "qFw"
};
