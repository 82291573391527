export default {
  "row": "qVW",
  "item-bulk": "qVu",
  "active": "qLS",
  "disabled": "qLc",
  "cell": "qLq body-2",
  "not-disabled": "qLZ",
  "item-amount-disabled": "qLR",
  "item-amount-credit": "qLQ",
  "no-padding": "qLe",
  "cell-content": "qLB",
  "centered-cell": "qLE",
  "item-counterparty-icon": "qLd mr-16",
  "avatar": "qLI",
  "item-counterparty-name": "qLt",
  "item-counterparty-name-status": "qLM",
  "disputing-chargeback": "qLP",
  "item-counterparty-name-text": "qLl",
  "item-amount": "qLX body-1",
  "item-amount-foreign": "qLC",
  "attachment-icon": "qLk",
  "item-method": "qLJ",
  "bank-account-avatar": "qLs",
  "attachment-status-dot": "qLF",
  "attached": "qLg",
  "missing": "qLD",
  "item-attachment": "qLV",
  "fadein-item": "qLL"
};
