export default {
  "row": "qHS",
  "cell": "qHc",
  "empty": "qHq",
  "icon": "qHZ",
  "type": "qHR",
  "amount-content": "qHQ",
  "cell-approve": "qHe",
  "approve-content": "qHB",
  "row-compact": "qHE"
};
