/* import __COLOCATED_TEMPLATE__ from './transfer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { FINANCING_TRANSFER_DECLINED_REASON } from 'qonto/constants/financing';
import {
  ERROR_ALREADY_CANCELED,
  ERROR_CANNOT_CANCEL,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import { ERROR_CANNOT_REFUND, ERROR_REFUND_ALREADY_REQUESTED } from 'qonto/models/direct-debit';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { formatExchangeRate } from 'qonto/utils/international-out/format';
import { getPaymentPurposeLabel } from 'qonto/utils/transfers';

export default class SidebarHeaderTransfer extends Component {
  Date = Date;

  @service abilities;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service flowLinkManager;
  @service segment;

  Disclaimer = Disclaimer.Block;

  get subject() {
    return this.args.highlightedItem.subject;
  }

  get shouldDisplayProofSection() {
    let { isDirectDebit, isIncome, isInternationalOutTransfer } = this.args.highlightedItem;
    return (
      !isIncome &&
      !isDirectDebit &&
      (variation('feature--boolean-international-proof-of-payment') || !isInternationalOutTransfer)
    );
  }

  get unavailableProofDisclaimer() {
    let { isInternationalOutTransfer } = this.args.highlightedItem;
    if (isInternationalOutTransfer) {
      return this.intl.t('transactions.sidebar.international-out.proof_not_available');
    }
    return this.intl.t('transactions.sidebar.proof_not_available');
  }

  get shouldDisplayGenerateProofButton() {
    let isCompleted = this.subject.get('isCompleted');

    if (
      variation('feature--boolean-international-proof-of-payment') &&
      this.args.highlightedItem.isInternationalOutTransfer
    ) {
      return isCompleted && this.abilities.can('read transfer') && this.subject.get('proofPdfUrl');
    }

    return (
      isCompleted && (this.args.isUserInitiator || this.abilities.can('generate proof transfer'))
    );
  }

  get shouldDisplayProofDisclaimer() {
    let defaultConditions =
      !this.subject.get('isDeclined') &&
      !this.subject.get('isCanceled') &&
      !this.args.highlightedItem.isSwiftIncome;

    if (
      variation('feature--boolean-international-proof-of-payment') &&
      this.args.highlightedItem.isInternationalOutTransfer
    ) {
      return defaultConditions && this.abilities.can('read transfer');
    }

    return defaultConditions && this.abilities.can('generate proof transfer');
  }

  get shouldDisplayRefundRequestedIndicator() {
    return this.subject.get('refundRequestedAt') && this.args.highlightedItem.debit;
  }

  get shouldDisplayRecallDisclaimer() {
    return this.args.highlightedItem.operationType === 'recall';
  }

  get paymentPurpose() {
    if (this.subject.get('fxPaymentPurpose')) {
      return getPaymentPurposeLabel({
        code: this.subject.get('fxPaymentPurpose'),
        country: this.subject.get('beneficiary.country'),
        currency: this.subject.get('beneficiary.currency'),
        intlService: this.intl,
      });
    }
  }

  get canRequestMandateRefund() {
    return this.abilities.can('requestRefund in mandate', this.subject);
  }

  get shouldDisplayAdditionalInfobox() {
    return (
      this.paymentPurpose ||
      this.canRequestMandateRefund ||
      this.shouldDisplayRefundRequestedIndicator ||
      this.shouldDisplayRecallDisclaimer ||
      this.shouldDisplayProofSection
    );
  }

  get shouldDisplayFinancingNotCompliantDisclaimer() {
    return this.subject.get('declinedReason') === FINANCING_TRANSFER_DECLINED_REASON;
  }

  requestRefundTask = dropTask(async close => {
    try {
      let record = await this.subject;
      await record.requestRefund();

      let message = this.intl.t('transactions.sidebar.request_refund_feedback.success');
      this.toastFlashMessages.toastSuccess(message);
    } catch (error) {
      let message;
      if (error.code === ERROR_CANNOT_REFUND) {
        message = this.intl.t('transactions.sidebar.request_refund_feedback.not_refundable');
      } else if (error.code === ERROR_REFUND_ALREADY_REQUESTED) {
        message = this.intl.t('transactions.sidebar.request_refund_feedback.already_requested');
      } else {
        message = this.intl.t('transactions.sidebar.request_refund_feedback.other_error');
      }

      this.toastFlashMessages.toastError(message);
    } finally {
      close();
    }
  });

  get shouldDisplayCancelButton() {
    return this.subject.get('cancelable') && this.abilities.can('cancel transfer');
  }

  get exchangeRate() {
    if (
      this.subject.get('localAmountCurrency') === this.subject.get('amountCurrency') ||
      this.args.highlightedItem.isSwiftIncome
    ) {
      return;
    }
    if (this.subject.get('beneficiary') && this.subject.get('beneficiary.fx')) {
      let fxRate = this.args.highlightedItem.localAmount / this.args.highlightedItem.amount;
      let value = formatExchangeRate(fxRate || 0);
      let [, decimalPart] = value.split('.');
      return {
        value,
        decimalPlacesCount: decimalPart ? decimalPart.length : 0,
      };
    }
  }

  get shouldDisplayRepeatTransfer() {
    return this.abilities.can('create transfer') && this.args.highlightedItem.isRepeatableTransfer;
  }

  cancelTransferTask = dropTask(async close => {
    this.segment.track('history_list_open_side_panel_cancel');
    try {
      let record = await this.subject;
      await record.cancel();

      let message = this.intl.t('transfers.cancel-transfer-feedback.success');
      this.toastFlashMessages.toastSuccess(message);
    } catch (error) {
      let message;
      if (error.code === ERROR_CANNOT_CANCEL) {
        message = this.intl.t('transfers.cancel-transfer-feedback.cannot-cancel');
      } else if (error.code === ERROR_ALREADY_CANCELED) {
        message = this.intl.t('transfers.cancel-transfer-feedback.already-canceled');
      } else {
        message = this.intl.t('transfers.cancel-transfer-feedback.other-error');
      }

      this.toastFlashMessages.toastError(message);
    } finally {
      close();
    }
  });

  @action
  openCancelModal() {
    this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.modals.cancel-transfer.title'),
      description: this.intl.t('transfers.modals.cancel-transfer.body', { htmlSafe: true }),
      cancel: this.intl.t('transfers.modals.cancel-transfer.cancel-button'),
      confirm: this.intl.t('transfers.modals.cancel-transfer.confirm-button'),
      confirmTask: this.cancelTransferTask,
    });
  }

  @action
  openRequestRefundModal() {
    this.modals.open('popup/confirmation', {
      title: this.intl.t('transactions.modals.request_refund.title'),
      description: this.intl.t('transactions.modals.request_refund.body', {
        amount: this.intl.formatMoney(this.subject.get('amount'), {
          currency: this.subject.get('amountCurrency'),
        }),
        emitterName: this.subject.get('emitterName'),
      }),
      cancel: this.intl.t('transactions.modals.request_refund.cancel_button'),
      confirm: this.intl.t('transactions.modals.request_refund.confirm_button'),
      confirmTask: this.requestRefundTask,
    });
  }

  @action
  repeatTransfer() {
    this.segment.track('history-side-panel_repeat-transfer_clicked');
    if (this.subject.get('beneficiary') && !this.subject.get('beneficiary.hidden')) {
      return this.flowLinkManager.replaceWith({
        name: 'sepa-transfer',
        stepId: 'details',
        queryParams: {
          repeatedTransferId: this.subject.get('id'),
          origin: TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER,
        },
      });
    } else {
      this.toastFlashMessages.toastError(this.intl.t('transfers.repeat.sepa.error.toast'));
    }
  }
}
