export default {
  "container": "qgl",
  "item": "qgX caption-bold",
  "hover": "qgC",
  "active": "qgk",
  "name-wrapper": "qgJ",
  "name": "qgs body-2",
  "count": "qgF",
  "actions-placeholder": "qgg",
  "actions": "qgD",
  "show": "qgV",
  "action-button": "qgL"
};
