export default {
  "header-cell": "qHn",
  "empty": "qHi",
  "header-content": "qHA caption-bold",
  "active": "qHY",
  "header-type": "qHh",
  "header-reason": "qHf",
  "header-status": "qHK",
  "header-amount": "qHG",
  "row-sidebar": "qHr",
  "hidden": "qHb",
  "animated": "qHy",
  "fadein-item": "qHU",
  "animated-cell": "qHj"
};
