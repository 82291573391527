export default {
  "modal": "qsQ",
  "section": "qse",
  "header": "qsB",
  "title": "qsE",
  "separator-title": "qsd",
  "icon": "qsI",
  "formats": "qst",
  "columns": "qsM",
  "column-list": "qsP",
  "draggable-column": "qsl",
  "column-field": "qsX",
  "column-selector": "qsC",
  "column-with-error": "qsk",
  "handle": "qsJ",
  "label-color": "qss",
  "disclaimer": "qsF"
};
