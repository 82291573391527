export default {
  "transaction": "qFn",
  "transaction-details": "qFi",
  "transaction-details-amount": "qFA body-1",
  "credit": "qFY",
  "negative-amount": "qFh",
  "transaction-details-description": "qFf body-2",
  "transaction-remove-cta": "qFK",
  "avatar": "qFG mr-16",
  "spinner": "qFr"
};
