export default {
  "transfer-header": "qVk",
  "infobox": "qVJ header-section",
  "proof-section": "qVs",
  "proof-link": "qVF",
  "download-icon": "qVg",
  "cancel-button": "qVD",
  "request-refund-button": "qVV",
  "refund-requested-indicator": "qVL",
  "recall-indicator": "qVT",
  "payment-purpose": "qVz",
  "payment-purpose-label": "qVa",
  "repeat-infobox": "qVH qVJ header-section",
  "additional-infobox": "qVO qVJ header-section",
  "repeat-transfer-action": "qVm",
  "financing-not-compliant-disclaimer": "qVp"
};
