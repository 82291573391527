export default {
  "display-block": "qmc",
  "pay-later-tooltip": "qmq",
  "disabled": "qmZ",
  "stepper": "qmR",
  "installments": "qmQ",
  "flex-items": "qme",
  "day-label": "qmB body-2",
  "installment-amount": "qmE body-1",
  "spacing": "qmd",
  "info-icon": "qmI",
  "detail": "qmt",
  "detail-value": "qmM",
  "extra": "qmP",
  "extra-value": "qml",
  "badge": "qmX",
  "title": "qmC",
  "link": "qmk"
};
